export const setupCommentFunctionality = () => {
  console.log("Setting up comment functionality");
  
  // First remove all existing event handlers
  $(".comment-form").off('submit');
  $(".like-comment-btn").off('click');
  $(".toggle-comments").off('click');
  
  // Comment form submissions
  $(".comment-form").on('submit', function(e) {
    e.preventDefault();
    e.stopPropagation(); // Prevent any other handlers from firing
    
    const form = $(this);
    
    // Disable form to prevent double submission
    const submitButton = form.find('button[type="submit"]');
    submitButton.prop('disabled', true);
    
    const listingId = form.data('listingId');
    const parentId = form.data('parentId');
    const contentInput = form.find('input[name="comment[content]"]');
    const content = contentInput.val();

    if (!content.trim()) {
      submitButton.prop('disabled', false);
      return;
    }

    const formData = new FormData(form[0]);
    
    fetch(form[0].action, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      credentials: 'same-origin',
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        if (parentId) {
          // Add reply to nested comments
          const parentThread = $(`#comment-${parentId}`);
          let nestedComments = parentThread.find('.nested-comments');
          
          if (nestedComments.length === 0) {
            nestedComments = $('<div class="nested-comments"></div>');
            parentThread.append(nestedComments);
          }
          
          nestedComments.append(data.comment_html);
          
          // Hide the reply form and clear any inline styles
          const infoItem = form.closest('.info-item');
          const replyForm = form.closest('.reply-form');
          
          infoItem.removeClass('active');
          replyForm.slideUp(function() {
            // Clear any inline styles after animation
            form.css('display', '');
            replyForm.css('display', '');
          });
        } else {
          // Add new root comment
          $(`#comments-list-${listingId}`).append(data.comment_html);
        }

        // Clear the form
        contentInput.val('');
        
        // Update comment count in toggle button
        $(`.toggle-comments[data-listing-id="${listingId}"] span`).text(`Comment (${data.comments_count})`);

        // Reinitialize functionality for new content
        setupCommentFunctionality();
        initializeReplyButtons();
      }
    })
    .catch(error => console.error('Error:', error))
    .finally(() => {
      // Re-enable the submit button
      submitButton.prop('disabled', false);
    });
  });

  // Like buttons
  $(".like-comment-btn").on('click', function(e) {
    e.preventDefault();
    const button = $(this);
    const commentId = button.data('commentId');
    const token = document.querySelector('meta[name="csrf-token"]').content;

    fetch(`/comments/${commentId}/like`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token,
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        const buttonText = data.liked ? 'Unlike' : 'Like';
        
        if (data.likes_count > 0) {
          button.text(`${buttonText} (${data.likes_count})`);
        } else {
          button.text(buttonText);
        }
      }
    })
    .catch(error => console.error('Error:', error));
  });

  // Load comments function
  const loadComments = async (listingId, commentsList, button) => {
    const loadingIndicator = document.querySelector(`#comments-list-${listingId} .loading-indicator`);
    loadingIndicator.style.display = 'block'; // Show spinner

    try {
      const response = await fetch(`/listings/${listingId}/comments`, {
        headers: {
          'Accept': 'text/html',
          'X-Requested-With': 'XMLHttpRequest'
        },
        credentials: 'same-origin'
      });

      const html = await response.text();
      commentsList.innerHTML = html;
      $(button).data('commentsLoaded', 'true');
      setupCommentFunctionality();
    } catch (error) {
      console.error('Error loading comments:', error);
      commentsList.innerHTML = `
        <div class="alert alert-danger">
          Error loading comments. Please try again.
        </div>
      `;
    } finally {
      loadingIndicator.style.display = 'none'; // Hide spinner
    }
  };

  // Toggle comments section
  $(".toggle-comments").on('click', function(e) {
    e.preventDefault();
    const button = $(this);
    const listingId = button.data('listingId');
    const commentSection = document.querySelector(`#comments-section-${listingId}`);
    const commentsList = document.querySelector(`#comments-list-${listingId}`);
    
    if (commentSection) {
      if (commentSection.style.display === 'none' || !commentSection.style.display) {
        commentSection.style.display = 'block';
        loadComments(listingId, commentsList, button);
      } else {
        commentSection.style.display = 'none';
      }
    }
  });

  // Initialize reply buttons
  initializeReplyButtons();
};

const initializeReplyButtons = () => {
  $(".like-share .reply-toggle").off('click');
  
  $(".like-share .reply-toggle").on('click', function(e) {
    e.preventDefault();
    
    const infoItem = $(this).parents('.info-item');
    const replyForm = infoItem.find('.reply-form');
    const commentForm = replyForm.find('.comment-form');
    
    $(".info-item").not(infoItem).removeClass('active');
    $(".reply-form").not(replyForm).each(function() {
      $(this).hide().find('.comment-form').hide();
    });
    
    infoItem.toggleClass('active');
    commentForm.css('display', '');
    
    replyForm.slideToggle(function() {
      if (replyForm.is(':visible')) {
        commentForm.show();
        commentForm.find('input[type="text"]').focus();
      }
    });
  });
};

// Make them available globally
window.setupCommentFunctionality = setupCommentFunctionality;
window.initializeReplyButtons = initializeReplyButtons;
